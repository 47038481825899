
.out_client{
    .clients{
      &:hover{
        background-color: #4eabf9;
        color: #fff;
        border: 1px solid #fff;
        transform: scale(1.025);
        transition: transform .42s;
        box-shadow: 0 0 5px inset;
        cursor: pointer;
        h4{
            transform: scale(1.05);
            transition: transform .42s;
        }
      }
    }
  }