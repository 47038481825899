.hero {
  .main-section {
    padding-bottom: 350px;
    @media (min-width: 768px) {
      padding-bottom: 45vh;
    }
    @media (min-width: 992px) {
      padding-bottom: 90vh;
    }
  }
  .main {
    contain: "";
    position: absolute;
    width: 100%;
    height: 334px;
    background-image: url(../../../assets/images/operational-dev.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 767px) {
      height: 600px;
    }
    @media (min-width: 992px) {
      height: 100%;
    }

    .container {
      height: 100%;
      position: relative;
      min-height: inherit;
      &::before {
        content: " ";
        display: table;
      }
    }
    .health {
      position: absolute;
      left: inherit;
      top: 50%;
      transform: translate(0, -50%);
      width: 94%;
      @media (min-width: 767px) {
        width: 97%;
      }
      @media (min-width: 992px) {
        right: 0;
        width: 50%;
      }
      .transparent {
        &::before {
          display: block;
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      .text-01 {
        position: relative;
        font-family: Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 900;
        line-height: 1.05em;
      }
      .text-02 {
        position: relative;
        font-family: Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 300;
        line-height: 1.2em;
        &::after {
          border-bottom: 7px solid #45aaff;
          content: "";
          display: block;
          margin: 12px 0 12px 0;
        }
      }
      p {
        position: relative;
        font-size: 16px;
        line-height: 1.3em;
      }
      .get-btn {
        position: relative;
        color: #ffffff;
        background-color: #1294ffe5;
        border-color: rgba(0, 0, 0, 0);
        border-radius: 0px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        &:hover {
          background-color: #1293ff;
        }
      }
    }
  }
  .service-portfolio {
    background-color: #304770;
    padding: 4rem 0;
    clip-path: polygon(50% 1%, 100% 0%, 100% 100%, 50% 99%, 0 100%, 0 0%);
    @media (min-width: 767px) {
      clip-path: polygon(50% 2%, 100% 0%, 100% 100%, 50% 98%, 0 100%, 0 0%);
    }
    @media (min-width: 991px) {
      clip-path: polygon(50% 4%, 100% 0%, 100% 100%, 50% 96%, 0 100%, 0 0%);
    }
    .service-box {
      background-color: #e5e5e5;
      border-color: #e5e5e5;
      color: #323232;
    }
    .teaser_box:hover .content.boxed_special.with_button {
      padding: 21px 20px 30px 20px !important;
      transition: all 100ms ease-in-out;
    }
  }
  .plethora_button {
    position: relative;
    a {
      font-size: 13px;
      padding: 7px 24px 7px 57px !important;
      background-color: #1293ff !important;
    }
    i {
      background-color: #0079de;
      position: absolute;
      left: 0;
      top: 0;
      width: 44px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 767px) {
  .pr-sm-0 {
    padding-right: 0px;
  }
  .pb-sm-4 {
    padding-bottom: 20px;
  }
}

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

.animated {
  animation-duration: 4s;
  animation-fill-mode: both;
}

@media (min-width: 767px) {
  .rhom1 {
    clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
  }
  .rhom2 {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
  }
}

.bg-clr11 {
  background-color: rgb(8, 142, 255);
  border: 1pt solid rgb(8, 142, 255);
}
.bg-clr12 {
  background-color: rgb(38, 156, 255);
  border: 1pt solid rgb(38, 156, 255);
}
.bg-clr13 {
  background-color: rgb(69, 170, 255);
  border: 1pt solid rgb(69, 170, 255);
}
