.header {
  background-color: #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
  .logo {
    font-size: 24px;
    @media (min-width: 767px) {
      font-size: 26px;
    }
    font-weight: 600;
    font-family: "Times New Roman", Times, serif;
    .sub {
      color: #4eabf9;
    }
  }

  img {
    width: 40px;
  }
  .active {
    color: #4eabf9 !important;
  }
  .dropdown-active {
    color: #4eabf9;
    &:hover {
      background-color: #4eabf9;
      color: #ffffff;
    }
  }

  .menu_container {
    .main_menu {
      list-style: none;
      margin: 0;
      padding: 0;
      .nav-item {
        float: left;
        background: inherit;
        width: auto;
        text-align: left;
        position: relative;
        &:hover {
          .dropdown-menu-top {
            display: block;
          }
        }
        a {
          font-size: 13px;
          line-height: 1em;
          padding: 16px 20px;
          display: block;
          text-decoration: none;
          font-weight: 600;
          text-transform: uppercase;
          @media (min-width: 767px) {
            padding: 6px 8px;
          }
          @media (min-width: 991px) {
            padding: 6px 18px;
          }
        }
      }
      @media (max-width: 767px) {
        .top-br {
          border-top: solid 1px #cccccc;
        }
        .btm-br {
          border-bottom: solid 1px #cccccc;
        }
        .service-mob {
          display: flex !important;
          justify-content: space-between;
        }
      }
      .inner-dropdown {
        a {
          font-size: 11px;
        }
        &::after {
          content: "\203A";
          position: absolute;
          font-family: Arial, sans-serif;
          top: 10px;
          right: 15px;
          font-size: 20px;
          line-height: 1em;
          font-weight: bold;
          @media (min-width: 767px) {
            top: 0px;
          }
        }
        &:hover {
          .dropdown-menu-inner {
            display: block;
          }
        }
      }
      .dropdown-menu-inner {
        @media (min-width: 767px) {
          position: absolute;
          left: 100%;
          top: -18px;
        }
      }
      .dropdown-menu-top,
      .dropdown-menu-inner {
        border: none;
        border-radius: inherit;
        @media (min-width: 767px) {
          border-bottom: 4px solid #4eabf9;
          padding: 17px 0px;
        }
        .dropdown-item {
          &:hover {
            background-color: #4eabf9;
            color: #ffffff;
          }
        }
      }
    }
  }
}
